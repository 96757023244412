import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import requests from '../requests';
import Spinner from '../Admin/UI/Spinner';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// images
import bio1 from '../assets/images/carousel-home/bio 1.png';
import bio2 from '../assets/images/carousel-home/bio 2.png';
import bio3 from '../assets/images/carousel-home/bio3.png';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Home = ({ location }) => {

    const addClassColaboradores = (e) => {e.target.parentNode.classList.add('viewInfo')}
    const removeClassColaboradores = (e) => e.target.parentNode.classList.remove('viewInfo');

    const [loading, setloading]     = useState(false);
    const [rut, setrut] = useState("");
    const [button_certs, setbutton_certs] = useState(false);
    const [verMasServicios, setverMasServicios] = useState(0);
    const [sendFormData, setsendFormData] = useState({ button: false, email: "", phone: 0, message: "", name: "" });
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElCovid, setAnchorElCovid] = useState(null);
    const [carouselIndex, setcarouselIndex] = useState(1);
    // Popover


    const useStyles = makeStyles((theme) => ({
        popover: {
          pointerEvents: 'none'
        },
        paper: {
          padding: theme.spacing(1),
        },
    }));
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handlePopoverOpenCovid = (event) => {
        setAnchorElCovid(event.currentTarget);
    };
    const handlePopoverCloseCovid = () => {
        setAnchorElCovid(null);
    };
    const open = Boolean(anchorEl);
    const openCovid = Boolean(anchorElCovid);
    const classes = useStyles();


    const handleChangeFormContact = (e) => {
        const { name, value } = e.target;
        if(!name) return;
        let sendData = sendFormData;

        sendData[name] = value;

        if(sendData.email && sendData.phone && sendData.name && sendData.message) {
            sendData.button = true;
        } else {
            sendData.button = false;
        }

        setsendFormData({...sendData});
    }

    const get_certificados = async () => {
        setloading(true);

        const req = new requests({ name: 'certs', extraUrl: `/get_certificate/${rut}` });

        try {
            let q = await req.prepareConfig();
            q.headers = {};
            q.config = { responseType: 'blob' };
            q = await q.get();
            setloading(false);

            if(q.type === "application/json") {
                swal("Error", "Error al obtener el certificado. Verifique si los datos ingresados son correctos.", 'error');
            } else {
                const url = window.URL.createObjectURL(new Blob([q]))
                const link = document.createElement('a')
                link.href= url
                link.setAttribute('download','Certificado Bio Salud.pdf')
                document.body.appendChild(link)
                link.click();
            }

        } catch (e) {
            console.log(e);
            swal('Error', e.message, 'error');
            window.location.reload();
        }

    }

    useEffect(() => {
        document.body.classList.add('homePage');

        if(location.search.includes('p')){
            swal('Atención', 'Debe revisar su mail para ver el estado del pago', 'warning');
        }

        let number = 1;
        setInterval(() => {
            let n = 4;
            if(number === n) {
                number = 1;
            } else {
                number += 1;
            }
            setcarouselIndex(number);
        }, 5000);

        return () => {
            document.body.classList.remove('homePage');
        }
    }, [])

    const openVerMasMenu = (menu) => {
        if(!menu) return;

        let data_to_insert = 0;
        // Si es el menu entonces lo cerramos
        if(verMasServicios !== menu) {
            data_to_insert = menu;
        }

        setverMasServicios(data_to_insert);
    }

    const sendForm = async () => {

        setloading(true);

        const req = new requests({ name: 'send_contact_mail', body: sendFormData });

        try {
            let q = await req.prepareConfig();
            q.headers = {};
            q = await q.post();
            setloading(false);

            swal('Excelente', 'Mensaje enviado correctamente', 'success');
            setsendFormData({ button: false, email: "", phone: 0, message: "", name: "" })

        } catch (e) {
            console.log(e);
            swal('Error', e.message, 'error');
            window.location.reload();
        }

    }

    const openModalVacunasMinisteriales = () => {
        swal.fire({
            imageUrl: require('../assets/images_swal/calendario-17-1-22.jpg')
        })
    }

    const modifyRUT = (e) => {
        let { value } = e.target;

        if(value.length === 8) {
            value += '-';
        }

        const regexp = new RegExp(/^([0-9a-z]{1,8})+(-)+([0-9a-z]{1})$/ig);

        setbutton_certs(regexp.test(value));

        setrut(value);
    }

    if(loading) {
        return <Spinner />
    }

    return (
        <>
        <header id="home" className="row">
            <div className="content col-12">
                <a href="/#" className="col-12 col-md-3">
                    <img src={require('../assets/icons/Biosalud.png')}  alt="logo" />
                </a>
                <nav className="d-flex">
                    <a href="/#servicios">Servicios</a>
                    <a href="/#certificados">Certificados</a>
                    <a href="/#aboutus">Quiénes somos</a>
                    <a href="/#clientes">Clientes</a>
                </nav>
            </div>
            <article>
                <div className="fixed-img">
                    <img alt="" src={require('../assets/images/carousel-home/edificio.png')} />
                </div>
                {/* Carousel */}
                <div className="rm-carousel">
                <article className={carouselIndex === 1 ? "item active" : "item"}>
                        <div className="image espejado-camilalila" style={{ backgroundImage: 'url("' + bio3 + '")' }}></div>
                        <span>
                            <h3>Nuestros horarios de atención son:</h3>
                            <h5>Lunes a viernes de 9 a 18 hs. Horario continuo
                                <br/>Sabados de 9 a 13:45 hs. (Solo vacunas privadas)
                                <br/>Vacunas COVID SOLO con agenda telefónica
                                <br/>Nuestros teléfonos de contacto son 222051750-223416873
                                <br/>Estamos ubicados en Colipi 126, Providencia.
                            </h5>
                        </span>
                    </article>
                    <article className={carouselIndex === 2 ? "item active" : "item"}>
                        <div className="image espejado-camilalila" style={{ backgroundImage: 'url("' + bio1 + '")' }}></div>
                        <span>
                            <h3>Vacunas ministrales y privadas.</h3>
                            <h5>Atención especializada a adultos y niños, centrada en el paciente y su familia.</h5>
                        </span>
                    </article>
                    <article className={carouselIndex === 3 ? "item active" : "item"}>
                        <div className="image" style={{ backgroundImage: 'url("' + bio2 + '")' }}></div>
                        <span>
                            <h3>Instructivo y vacunas para viajes.</h3>
                            <h5>Entregamos orientación a todas las personas que estan planificando viajes y desconocen las vacunas requeridas de acuerdo al país del destino.</h5>

                        </span>
                    </article>
                    <article className={carouselIndex === 4 ? "item active" : "item"}>
                        <div className="image espejado-camilalila" style={{ backgroundImage: 'url("' + bio3 + '")' }}></div>
                        <span>
                            <h3>Vacunas ministrales y privadas.</h3>
                            <h5>Atención especializada a adultos y niños, centrada en el paciente y su familia.</h5>
                        </span>
                    </article>
                </div>
                {/* Fixed IMG */}

            </article>
        </header>
        {/* Fixed buttons */}
        <div className="fixed-buttons-right">
            <div>
                <a href="/#"><i className="fab fa-whatsapp"></i></a>
            </div>
        </div>

        {/* Seccion Quienes Somos */}
        <div className="content col-12 mt-3" id="aboutus">
            <a href="/#" className="col-12 col-md-3">
                <img src={require('../assets/icons/Biosalud.png')}  alt="logo" />
            </a>
            <nav className="not-title">
                <h4>Quiénes Somos</h4>
            </nav>
        </div>
        <section className="home container-fluid" id="about-us">
            <div className="row d-none d-md-flex">
                <div className="col-12 col-md-8 text-justify">
                    <p>
                        La empresa se fundó  en el año 2008 por   Solange Cáceres con el propósito de entregar al público  un espacio de seguridad, calidad y orientación en  relación al.   uso de vacunas.   a  lo     largo de     todo el  ciclo vital. En el transcurso de los años  la  empresa   fue   creciendo  y aumentó el número de servicios prestados; vacunaciones  en terreno para empresas,     colegios, institutos y    universidades, hospitales, hogares del adulto mayor, entre otros.
                    </p>
                    <p>
                        Nuestro   nuevo proyecto se radica en entregar       procedimientos de enfermería; como curaciones de menor y mayor        complejidad,  administración de      medicamentos   intramusculares,   endovenosos    (directos y de larga duración), intradérmicos, etc.
                    </p>
                </div>
                <div className="col-4 d-none d-md-flex personal-altamente-calificado">
                    <span>
                        <img src={require('../assets/icons/icono_aboutus.webp')} alt="personal-altamente-calificado"/>
                    </span>
                    <h4>
                        Personal Altamente <br/> Calificado
                    </h4>
                </div>
            </div>
        </section>

        {/* Seccion Servicios */}
        <div className="content col-12 mt-3" id="servicios">
            <a href="/#" className="col-12 col-md-3">
                <img src={require('../assets/icons/Biosalud.png')}  alt="logo" />
            </a>
            <nav className="not-title">
                <h4>Servicios</h4>
            </nav>
        </div>
        <section className="container-fluid home" id="services">
            <div className="row">
                <article className="col-12 col-md-4">
                    {verMasServicios === 1 &&
                        <>
                        <div className="verMasMenu">
                            <p>
                                Lorem
                            </p>
                        </div>
                        <a onClick={() => openVerMasMenu(1)} className="custom-biosalud d-none d-md-block" href="/#contact">Contáctenos</a>
                        </>
                    }
                    {verMasServicios !== 1 &&
                    <>
                    <div>
                        <img alt="vacunas-ministeriales" src={require('../assets/images/vacunas-ministeriales.png')}/>
                        <h3 className="mt-2 primary-color font-weight-bold">Vacunas ministeriales y privadas</h3>
                        <p>Atención especializada a adultos y niños, centrada en el paciente y su familia.</p>
                    </div>
                    <button className="custom-biosalud d-none d-md-block" type="button" onClick={() => openModalVacunasMinisteriales()}>Ver más</button>
                    </>
                    }
                </article>
                <article className="col-12 col-md-4">
                    {verMasServicios === 2 &&
                        <>
                        <div className="verMasMenu">
                            <ul>
                                <li><strong>Fiebre Amarilla:</strong>Se administra desde los 8 meses de edad, y debes colocarla al menos 10 días antes del viaje. Recuerda traer tu orden medica obligatorio. Esta vacuna es de por vida.</li>
                                <li><strong>Hepatitis A y B:</strong>Esta vacuna esta compuesta de 3 dosis, separadas de 0 -1-6 meses, una vez completado el esquema son 35 años de inmunidad</li>
                                <li><strong>Tifus:</strong>Esta vacuna es 1 dosis cada 3 años.</li>
                                <li><strong>Tétano:</strong>Para los chilenos nacidos después de 1975, es solo una dosis que dura 10 años de inmunidad, para los nacidos antes de 1975, son 3 dosis separadas de 0-1-6 meses.</li>
                                <li><strong>Antirrábica:</strong>El esquema para viajeros es de 3 dosis separadas de 0-7-21 días. Para otras vacunas, contáctanos a <a href='mailto:secretaria@serviciosbiosalud.cl'>secretaria@serviciosbiosalud.cl</a></li>
                            </ul>
                        </div>
                        <a onClick={() => openVerMasMenu(2)} className="custom-biosalud d-none d-md-block" href="/#contact">Contáctenos</a>
                        </>
                    }
                    {verMasServicios !== 2 &&
                    <>
                        <div>
                            <img alt="vacunas-ministeriales" src={require('../assets/images/instructivo-vacunas.png')}/>
                            <h3 className="mt-2 primary-color font-weight-bold">Instructivo y vacuna para viajes</h3>
                            <p>Entregamos orientación a todas las personas que están planificando un viaje y    desconocen     las vacunas requeridas  de     acuerdo al país de destino.</p>
                        </div>
                        <button className="custom-biosalud d-none d-md-block" type="button" onClick={() => openVerMasMenu(2)}>Ver más</button>
                    </>
                    }
                </article>
                <article className="col-12 col-md-4">
                    {verMasServicios === 3 &&
                        <>
                        <div className="verMasMenu">
                            <p>
                            Realizamos servicios de vacunación en terreno, de Arica a Punta Arenas, contáctanos a <a href="mailto:finanzas@serviciosbiosalud.cl">finanzas@serviciosbiosalud.cl</a>.
                            </p>
                        </div>
                        <a onClick={() => openVerMasMenu(3)} className="custom-biosalud d-none d-md-block" href="/#contact">Contáctenos</a>
                        </>
                    }
                    {verMasServicios !== 3 &&
                        <>
                        <div>
                            <img alt="vacunas-ministeriales" src={require('../assets/images/vacunas-en-terreno.png')}/>
                            <h3 className="mt-2 primary-color font-weight-bold">Vacunas en terreno Para empresas</h3>
                            <p>Realizamos tratamientos endovenosos de  corta  o  larga  duración  y  otros  en nuestro centro como en domicilios. Atendemos   a      colegios, institutos  y universidades, hospitales, hogares  del adulto mayor, entre otros.</p>
                        </div>
                        <button className="custom-biosalud d-none d-md-block" type="button" onClick={() => openVerMasMenu(3)}>Ver más</button>
                        </>
                    }
                </article>
            </div>

        </section>

        {/* Seccion Certificados */}
        <div className="content col-12 mt-3" id="certificados">
            <a href="/#" className="col-12 col-md-3">
                <img  src={require('../assets/icons/Biosalud.png')}  alt="logo" />
            </a>
            <nav className="not-title">
                <h4>Certificados</h4>
            </nav>
        </div>
        <section className="container-fluid home" id="certs">
            <article className="row">
                <p className="col-12 text-justify">Descarga tu certificado de vacunación, ingresando tu Rut, sin punto y con guion. Si no se descarga automáticamente, debes enviar un correo a <a href="mailto:coordinaciones@serviciosbiosalud.cl">coordinaciones@serviciosbiosalud.cl</a> y te lo enviaran en un plazo de 5 días hábiles. <br />IMPORTANTE: Los registros de vacunación son conservados por 5 años.</p>
            </article>
            <form onSubmit={get_certificados} className="mt-5">
                <div className="form-group">
                    <label className="col-12 col-md-2">Ingresa tu RUT</label>
                    <input className="form-control col-12 col-md-10" type="text" placeholder="11111111-1" onChange={modifyRUT} value={rut} maxLength="10" required/>
                </div>
                {button_certs &&
                    <button type="submit"  className="col-md-2 offset-md-10 col-12 custom-biosalud">Continuar</button>
                }
            </form>
        </section>

        {/* Seccion Clientes */}
        <div className="content col-12 mt-3" id="clientes">
            <a href="/#" className="col-12 col-md-3">
                <img src={require('../assets/icons/Biosalud.png')}  alt="logo" />
            </a>
            <nav className="not-title">
                <h4>Clientes</h4>
            </nav>
        </div>
        <section className="container-fluid home" id="clients">
            <div className="row">
                <article className="col">
                    <img  alt="" src={require('../assets/images/komatsu.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/vida_security.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/jpmorgan.png')} />
                </article>
            </div>
            <div className="row">
                <article className="col">
                    <img  alt="" src={require('../assets/images/usdc.jpg')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/aiep_universidad_andres_bello.jpg')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/universidad_autonoma_chile.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/limchile.png')} />
                </article>
            </div>
            <div className="row">
                <article className="col">
                    <img  alt="" src={require('../assets/images/sodexo.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/ua.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/iss.png')} />
                </article>
            </div>
            <div className="row">
                <article className="col">
                    <img  alt="" src={require('../assets/images/udla.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/inacap.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/ipchile.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/c&g_servicios_industriales.png')} />
                </article>
            </div>
            <div className="row">
                <article className="col">
                    <img  alt="" src={require('../assets/images/arauco.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/claro.png')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/universidad_san_sebastian.jpeg')} />
                </article>
                <article className="col">
                    <img  alt="" src={require('../assets/images/ucc.png')} />
                </article>
            </div>
        </section>

        {/* Seccion Contactos */}
        <div className="content col-12 mt-3" id="contacto">
            <a href="/#" className="col-12 col-md-3">
                <img src={require('../assets/icons/Biosalud.png')}  alt="logo" />
            </a>
            <nav className="not-title">
                <h4>Contacto</h4>

            </nav>
        </div>
        <section className="container-fluid home" id="contact">
            <div className="row">
                <div className="col-12 col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17234.09373841962!2d-70.61260773374437!3d-33.41907943421223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf69402af0bf%3A0x8f2d429724d8de9f!2sColipi%20126%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1610991607720!5m2!1ses-419!2sar" height="450" frameBorder="0" style={{border: 0, width: '100%'}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
                <div className="col-12 col-md-6">
                    <h3 className="mt-2 primary-color font-weight-bold">Dónde encontrarnos</h3>
                    <article>
                        <h6>Dirección</h6>
                        <p>Colipi 126, Providencia</p>
                        <p>Cercano al metro Tobalaba - Los Leones</p>
                    </article>
                    <article>
                        <h6>Teléfonos</h6>
                        <p>+56 2 2205 1750 (Horas COVID)</p>
                        <p>+56 2 2341 6873</p>
                    </article>
                    <article>
                        <h6>Horario Vacunatorio</h6>
                        <p>Lunes a Viernes 09:00 a 18:00 horas.</p>
                        <p>Sábados: 09:00 a 13:45 horas. (Solo vacunas privadas)</p>
                    </article>
                    <form onSubmit={sendForm}>
                        <div className="row">
                            <div className="form-group col-12 col-md-6">
                                <input type="text" name="name" className="form-control" placeholder="Nombre" required onChange={handleChangeFormContact} />
                                <input type="email" name="email" className="form-control mt-1" placeholder="Email" required onChange={handleChangeFormContact} />
                                <input type="text" name="phone" className="form-control mt-1" placeholder="Asunto" required onChange={handleChangeFormContact} />
                            </div>
                            <div className="form-group col-12 col-md-6">
                                <textarea name="message" className="form-control" placeholder="Mensaje" onChange={handleChangeFormContact}>

                                </textarea>
                            </div>
                        </div>
                        {sendFormData.button &&
                            <button type="submit" className="custom-biosalud col-12 float-right mb-5">Enviar</button>
                        }
                    </form>
                </div>
            </div>
        </section>
        <footer className="container-fluid">
            <article className="row">
                <div className="col-12 col-md-9">
                    <span className="text-center-foot">
                        <i className="ti-location-pin d-inline"></i>
                        <p className="d-inline ml-1">Dirección</p>
                    </span>
                    <span className="text-center-foot">
                        <p>Colipi 126, Providencia, Santiago de Chile.</p>
                        <p>Cercano al metro Tobalaba - Los Leones</p>
                    </span>
                </div>
                <div className="col-3  d-none d-md-inline">
                    <a href="/#"><i className="ti-facebook"></i></a>
                    <a href="/#"><i className="ti-twitter-alt"></i></a>
                    <a href="/#"><i className="fab fa-google-plus-g"></i></a>
                </div>
            </article>
            <article className="row mt-2">
                <span className="col-12 text-center-foot">
                    <i className="icon-phone d-inline"></i>
                    <p className="d-inline ml-1">Teléfonos</p>
                </span>
                <span className="col-12 text-center-foot">
                    <p>+56 2 2205 1750 (Exclusivo consultas médicas)</p>
                </span>
                <span className="col-12 text-center-foot">
                    <p>+56 2 2341 6873 (Exclusivo vacunas)</p>
                </span>
            </article>
            <article className="row mt-2">
                <span className="col-12 text-center-foot">
                    <i className="icon-clock d-inline"></i>
                    <p className="d-inline ml-1">Horario vacunatorio</p>
                </span>
                <span className="col-12 text-center-foot">
                    <table className="mx-auto mx-md-0 mt-2">
                        <tbody>
                            <tr>
                                <td>Lunes a Viernes</td>
                                <td>09:00 a 18:00 horas.</td>
                            </tr>
                            <tr>
                                <td>Sábados</td>
                                <td>09:00 a 13:45 horas.</td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            </article>
            <article className="row  d-inline d-md-none">
                <div className="mt-4 mt-md-2 col-md-12 d-inline-flex justify-content-around">
                    <a href="/#"><i className="ti-facebook"></i></a>
                    <a href="/#"><i className="ti-twitter-alt"></i></a>
                    <a href="/#"><i className="fab fa-google-plus-g"></i></a>
                </div>
            </article>
        </footer>
        </>
    )
}

export default Home;
